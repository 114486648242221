import { useMemo } from 'react'
// routes
import { paths } from 'src/routes/paths'
// locales
// components
import Iconify from 'src/components/iconify'

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} />

const ICONS = {
  user: icon('carbon:user-data'),
  dashboard: icon('carbon:dashboard'),
  products: icon('carbon:inventory-management'),
  reports: icon('carbon:report'),
  events: icon('carbon:calendar'),
  orders: icon('carbon:shopping-cart'),
}

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        subheader: 'Dashboard',
        items: [
          { title: 'Início', path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: 'Usuários', path: paths.dashboard.user, icon: ICONS.user },
          { title: 'Produtos', path: paths.dashboard.products, icon: ICONS.products },
          { title: 'Eventos', path: paths.dashboard.events.root, icon: ICONS.events },
          { title: 'Vendas', path: paths.dashboard.orders, icon: ICONS.orders },
          { title: 'Relatórios', path: paths.dashboard.reports, icon: ICONS.reports },
        ],
      },
    ],
    [],
  )

  return data
}
