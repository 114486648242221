import { act, useMemo, useState } from 'react'
// @mui
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { OptionType } from 'src/components/hook-form/rhf-autocomplete'
import { useBoolean } from 'src/hooks/use-boolean'
import { LoadingButton } from '@mui/lab'
import useDebounce from 'src/hooks/use-debounce'
import useCategories from 'src/service/categories.service'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import { Controller, useFieldArray } from 'react-hook-form'
import Iconify from 'src/components/iconify'
import { RHFTextField } from 'src/components/hook-form'
import RHFRadioGroup from 'src/components/hook-form/rhf-radio-group'
import useProducts from 'src/service/products.service'
import { masks } from 'src/utils/masks'
import UserCreateEditForm from '../../users/user-create-edit-view'

// ----------------------------------------------------------------------

interface EventStepperThreeProps {
  methods: any
  activeStep: number
  createId?: string
  handleBack: () => void
  handleNext: (data: any) => void
  isLoading?: boolean
}

export default function EventStepperThree({
  activeStep,
  handleBack,
  handleNext,
  methods,
  isLoading,
}: EventStepperThreeProps) {
  const [search, setSearch] = useState('')
  const searchDebounce = useDebounce(search, 500)

  const [selectedValue, setSelectedValue] = useState<any>()
  const quickEdit = useBoolean()

  const { control, watch } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'categories',
  })

  const {
    isLoading: isLoadingUser,
    isFetching,
    data,
  } = useCategories(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {
      search: searchDebounce,
    },
  )

  const options = useMemo(() => {
    let categories: any = data?.data || []
    categories = categories.map((category: any) => ({
      label: category.name,
      value: category._id,
    }))
    if (!categories) return []

    const hasOption = categories.find((category: any) => category.name === search)

    if (!hasOption && search) {
      return [...categories, { label: `Adicionar: ${search}`, value: search, isNew: true }]
    }

    return categories
  }, [search, data?.data])

  const onSelect = (newCategory: any) => {
    append({
      name: newCategory.isNew ? newCategory.value : newCategory.label,
      _id: newCategory.value,
      isNew: newCategory.isNew,
      generalTax: {
        enabled: false,
        value: 0,
      },
      transfer: {
        type: 'brut',
        value: 0,
      },
    })
    setSearch('')
  }

  return (
    <>
      <Stack gap={3}>
        <Stack gap={3}>
          <Autocomplete
            selectOnFocus
            clearOnEscape
            freeSolo
            handleHomeEndKeys
            clearOnBlur
            id='categorie-create'
            options={options}
            isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
            getOptionLabel={(option: OptionType | string) => (option as OptionType)?.label}
            onChange={(event, newValue: any) => {
              if (newValue) {
                onSelect(newValue)
              }
              setSelectedValue(newValue)
            }}
            value={selectedValue}
            loading={isLoadingUser || isFetching}
            inputValue={search}
            onInputChange={(event, newInputValue) => {
              setSearch(newInputValue)
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => <TextField label='Adicionar categorias' {...params} />}
          />
          {fields.length > 0 ? (
            <Categories watch={watch} remove={remove} control={control} fields={fields} />
          ) : (
            <Typography variant='subtitle2' align='center'>
              Selecione categorias para o seu evento, caso não encontre a categoria desejada, você
              pode criar uma nova!
            </Typography>
          )}
        </Stack>
      </Stack>

      <Stack alignItems='flex-end' justifyContent='space-between' direction='row' sx={{ mt: 3 }}>
        <Button disabled={activeStep === 0} variant='contained' onClick={handleBack}>
          Voltar
        </Button>
        <LoadingButton loading={isLoading} variant='contained' type='submit'>
          Finalizar
        </LoadingButton>
      </Stack>
      <UserCreateEditForm
        onRefleshTable={() => {}}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </>
  )
}

interface CategoriesProps {
  fields: any
  control: any
  watch: any
  remove: (index: number) => void
}

const Categories = ({ fields, control, watch, remove }: CategoriesProps) => (
  <>
    {fields.map((field: any, index: number) => (
      <Category
        control={control}
        key={field._id}
        field={field}
        index={index}
        watch={watch}
        remove={remove}
      />
    ))}
  </>
)

interface CategoryProps {
  field: any
  index: number
  watch: any
  control: any
  remove: (index: number) => void
}

const Category = ({ field, index, remove, watch, control }: CategoryProps) => {
  const tax = watch(`categories.${index}.generalTax.value`)
  return (
    <Accordion key={field._id}>
      <AccordionSummary expandIcon={<Iconify icon='eva:arrow-ios-downward-fill' />}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap={10}
          rowGap={3}
        >
          <Stack alignItems='center' direction='row'>
            <IconButton color='error' onClick={() => remove(index)}>
              <Iconify color='red' icon='solar:trash-bin-trash-bold' />
            </IconButton>
            <Typography variant='subtitle1'>{field.name}</Typography>
          </Stack>

          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {field?.products?.length} itens
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          <Stack gap={2}>
            <Typography variant='h5'>Configurações de repasse</Typography>

            <RHFRadioGroup
              row
              label='Tipo de repasse'
              name={`categories.${index}.transfer.type`}
              options={[
                { label: 'Bruto', value: 'brut' },
                { label: 'Líquido', value: 'liquid' },
              ]}
            />
            <RHFTextField
              size='small'
              mask='number'
              name={`categories.${index}.transfer.value`}
              label='Repasse (%)'
            />

            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name={`categories.${index}.generalTax.enabled`}
                  control={control}
                  render={({ field: fieldSwitch }) => (
                    <Switch
                      {...fieldSwitch}
                      checked={fieldSwitch.value}
                      onChange={(event) => fieldSwitch.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label={
                <RHFTextField
                  size='small'
                  fullWidth
                  disabled={!tax}
                  mask='number'
                  name={`categories.${index}.generalTax.value`}
                  label='Taxa fixa'
                />
              }
            />
            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name={`categories.${index}.validateQrcode.enabled`}
                  control={control}
                  render={({ field: fieldSwitch }) => (
                    <Switch
                      {...fieldSwitch}
                      checked={fieldSwitch.value}
                      onChange={(event) => fieldSwitch.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label='Validar QrCode'
            />
            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name={`categories.${index}.controlStock`}
                  control={control}
                  render={({ field: fieldSwitch }) => (
                    <Switch
                      {...fieldSwitch}
                      checked={fieldSwitch.value}
                      onChange={(event) => fieldSwitch.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label='Controlar estoque'
            />
          </Stack>
          <Stack gap={2}>
            <Typography variant='h5'>Produtos:</Typography>
            <ProductsSelection index={index} control={control} />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

interface ProductsSelectionProps {
  index: number
  control: any
}

const ProductsSelection = ({ index, control }: ProductsSelectionProps) => {
  const [search, setSearch] = useState('')
  const searchDebounce = useDebounce(search, 500)

  const {
    isLoading: isLoadingUser,
    isFetching,
    data,
  } = useProducts(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {
      search: searchDebounce,
    },
  )

  const options = useMemo(() => {
    const products: any = data?.data || []

    if (!products) return []

    const hasOption = products.find((category: any) => category.name === search)

    if (!hasOption && search) {
      return [...products, { name: `Adicionar: ${search}`, _id: search, isNew: true }]
    }

    return products
  }, [search, data?.data])

  const { fields, append, remove } = useFieldArray({
    control,
    name: `categories.${index}.products`,
  })

  const onSelect = (newProduct: any) => {
    append({
      name: newProduct.name,
      _id: newProduct._id,
      isNew: true,
      isNewProduct: !!newProduct.isNew,
      price: newProduct.price ? masks.currency(newProduct.price) : 0,
      cost: newProduct.cost ? masks.currency(newProduct.cost) : 0,
      newPrice: newProduct.price ? masks.currency(newProduct.price) : 0,
      stock: 0,
      minimumStock: 0,
      currentStock: 0,
    })
  }

  return (
    <Stack gap={2}>
      <Autocomplete
        selectOnFocus
        clearOnEscape
        freeSolo
        handleHomeEndKeys
        clearOnBlur
        id={`products-create-${index}`}
        options={options}
        isOptionEqualToValue={(option: any, value: any) => option?._id === value?._id}
        getOptionLabel={(option: any) => option?.name}
        onChange={(event, newValue: any) => {
          if (newValue) {
            onSelect(newValue)
          }
        }}
        loading={isLoadingUser || isFetching}
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        renderOption={(props, option) => (
          <li {...props} key={option._id}>
            {option.name}
          </li>
        )}
        renderInput={(params) => <TextField label='Adicionar produtos' {...params} />}
      />
      {fields.map((field: any, index2: number) => (
        <>
          <Stack key={field.id} spacing={1.5}>
            <Typography variant='body2'>{field.name}</Typography>
          </Stack>

          <Stack
            key={field.id}
            direction={{ xs: 'column', md: 'row' }}
            alignItems='center'
            spacing={2}
          >
            <RHFTextField
              name={`categories.${index}.products.${index2}.price`}
              mask='currency'
              size='small'
              disabled
              fullWidth
              label='Valor'
              InputLabelProps={{ shrink: true }}
            />
            <RHFTextField
              name={`categories.${index}.products.${index2}.newPrice`}
              mask='currency'
              size='small'
              fullWidth
              label='Novo Valor'
              InputLabelProps={{ shrink: true }}
            />
            <RHFTextField
              name={`categories.${index}.products.${index2}.cost`}
              mask='currency'
              size='small'
              fullWidth
              label='Custo'
              InputLabelProps={{ shrink: true }}
            />
            <RHFTextField
              name={`categories.${index}.products.${index2}.stock`}
              mask='number'
              size='small'
              fullWidth
              label='Estoque'
              InputLabelProps={{ shrink: true }}
            />
            <RHFTextField
              name={`categories.${index}.products.${index2}.minimumStock`}
              mask='number'
              size='small'
              fullWidth
              label='Estoque Mínimo'
              InputLabelProps={{ shrink: true }}
            />
            <RHFTextField
              name={`categories.${index}.products.${index2}.currentStock`}
              mask='number'
              size='small'
              fullWidth
              label='Estoque Atual'
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name={`categories.${index}.products.${index2}.active`}
                  control={control}
                  render={({ field: fieldSwitch }) => (
                    <Switch
                      {...fieldSwitch}
                      checked={fieldSwitch.value}
                      onChange={(event) => fieldSwitch.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label='Estado'
            />
          </Stack>
          <Stack alignItems='flex-end' key={field.id} spacing={1.5}>
            <Button
              size='small'
              color='error'
              startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}
              onClick={() => remove(index2)}
            >
              Remover
            </Button>
          </Stack>
        </>
      ))}
    </Stack>
  )
}
