import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Container from '@mui/material/Container'
// utils
// routes
import { paths } from 'src/routes/paths'
import { useParams, useRouter } from 'src/routes/hook'
// types
import { IEventItem } from 'src/types/events'
// assets
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider from 'src/components/hook-form'
import { LoadingScreen } from 'src/components/loading-screen'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import axiosInstance from 'src/utils/axios'
import { ConfirmDialog } from 'src/components/custom-dialog'
import { useBoolean } from 'src/hooks/use-boolean'

import { Helmet } from 'react-helmet-async'
import { EventStepOneSchema } from 'src/validators/event.schema'
import { masks } from 'src/utils/masks'
import EventStepper from './stepper-event/event-stepper'
import EventStepperOne from './stepper-event/event-stepper-one'
import EventStepperTwo from './stepper-event/event-stepper-two'
import EventStepperThree from './stepper-event/event-stepper-three'

export default function EventCreateEditView() {
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState<IEventItem>()
  const [activeStep, setActiveStep] = useState(0)

  const [createId, setCreateId] = useState()
  const { id } = params
  const router = useRouter()
  const confirm = useBoolean()

  const { enqueueSnackbar } = useSnackbar()

  async function fetchUser(userId: string) {
    try {
      setLoading(true)

      const { data: response } = await axiosInstance.get(`/event/${userId}`)

      Object.keys(response.event).forEach((event) => {
        setValue(`${event}`, response.event[event])
      })

      setValue(
        'managers',
        response.event.managers?.map((manager: any) => ({
          label: manager?.name,
          value: manager?._id,
        })),
      )

      setValue(
        'categories',
        response.categories?.map((cat: any) => {
          const products: any = response.products?.filter((prod: any) => prod.category === cat._id)
          return {
            ...cat,
            products: products.map((prod: any) => ({
              ...prod,
              price: prod.price ? masks.currency(prod.price) : null,
              newPrice: prod.price ? masks.currency(prod.price) : null,
              cost: prod.cost ? masks.currency(prod.cost) : null,
            })),
          }
        }),
      )

      setValue('date', new Date(response.event.date))

      setCurrentUser(response)
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Erro ao remover usuario !', {
          variant: 'error',
        })
      }
    }
    setLoading(false)
  }

  const methods = useForm<any>({
    resolver: yupResolver(EventStepOneSchema),
    defaultValues: {
      taxConfig: {
        credit: false,
        debit: false,
        money: false,
        pix: false,
        card: false,
      },
    },
  })

  const {
    setValue,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods

  const handleFinish = useCallback(async () => {
    try {
      const payload: any = getValues()

      const categories = payload.categories.map((cat: any) => ({
        ...cat,
        products: cat.products.map((prod: any) => ({
          ...prod,
          price: prod.price ? masks.unmask(prod.price) : null,
          newPrice: prod.newPrice ? masks.unmask(prod.newPrice) : null,
          cost: prod.cost ? masks.unmask(prod.cost) : null,
        })),
      }))

      await axiosInstance.put(`/event/${id || createId}/config`, {
        categories,
      })

      enqueueSnackbar('Produtos atualizados com sucesso!')
      router.push(paths.dashboard.events.root)
    } catch (error) {
      console.log(error)
      enqueueSnackbar('Erro ao criar evento !', {
        variant: 'error',
      })
    }
  }, [createId, enqueueSnackbar, getValues, id, router])

  const onSubmit = useCallback(async () => {
    try {
      console.log(activeStep)

      if (activeStep === 2) {
        await handleFinish()
      } else {
        const payload: any = getValues()

        payload.managers = payload.managers.map((manager: any) => manager.value)
        let userId = id

        if (userId) {
          await axiosInstance.put(`/event/${userId}`, payload)
        } else {
          const { data: response } = await axiosInstance.post('/event', payload)

          setCreateId(response._id)
          userId = response._id
        }

        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Evento criado com sucesso!',
        )
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } catch (error) {
      enqueueSnackbar('Erro ao criar evento !', {
        variant: 'error',
      })
    }
  }, [activeStep, getValues, id, enqueueSnackbar, currentUser, handleFinish])

  const handleDelete = useCallback(
    async (eventoId: string) => {
      try {
        await axiosInstance.delete(`/events/${eventoId}`)

        enqueueSnackbar('Evento removido com sucesso !')
        router.push(paths.dashboard.events.root)
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover evento !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, router],
  )

  const renderLoading = (
    <LoadingScreen
      sx={{
        borderRadius: 1.5,
        bgcolor: 'background.default',
      }}
    />
  )

  const handleNext = () => {
    if (activeStep !== 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    if (id) {
      fetchUser(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (loading) return renderLoading

  return (
    <>
      <Helmet>
        <title>Eventos - Criar</title>
      </Helmet>

      <Container maxWidth='lg'>
        <CustomBreadcrumbs
          heading={id ? 'Editar Evento' : 'Criar novo Evento'}
          links={[
            {
              name: 'Ínicio',
              href: paths.dashboard.root,
            },
            {
              name: 'Eventos',
              href: paths.dashboard.events.root,
            },
            { name: currentUser?.name || 'Novo Evento' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack gap={3}>
                  <EventStepper activeStep={activeStep} />

                  {activeStep === 0 && (
                    <EventStepperOne
                      methods={methods}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                    />
                  )}
                  {activeStep === 1 && (
                    <EventStepperTwo
                      methods={methods}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      isLoading={isSubmitting}
                      handleNext={handleNext}
                    />
                  )}
                  {activeStep === 2 && (
                    <EventStepperThree
                      createId={createId}
                      methods={methods}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      isLoading={isSubmitting}
                      handleNext={handleNext}
                    />
                  )}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title='Deletar'
          content={<>Tem certeza de que deseja excluir esse evento?</>}
          action={
            <Button
              variant='contained'
              color='error'
              onClick={() => {
                if (id) handleDelete(id)
                confirm.onFalse()
              }}
            >
              Deletar
            </Button>
          }
        />
      </Container>
    </>
  )
}
